
interface Props {
    length: number
}

const Placeholder: React.FC<Props> = ({ length }) => (
    <div className={`container w-full mx-auto grid grid-cols-1 md:grid-cols-4 gap-6`}>
        {Array.from({ length: length }).map((_, index) => (
            <div
                key={index}
                role="status"
                className="max-w-sm col-span-1 p-4 border border-gray-200 rounded shadow animate-pulse md:p-6 "
            >
                <div className="flex items-center justify-center h-48 mb-4 bg-gray-300 rounded "></div>
                <div className="h-2.5 bg-gray-200 rounded-full w-48 mb-4"></div>
                <div className="h-2 bg-gray-200 rounded-full mb-2.5"></div>
                <div className="h-2 bg-gray-200 rounded-full mb-2.5"></div>

                <div className="h-2 bg-gray-200 rounded-full mb-2.5"></div>
                <div className="h-2 bg-gray-200 rounded-full mb-2.5"></div>
                <div className="h-2 bg-gray-200 rounded-full"></div>

                <div className="flex items-center mt-4"></div>
                <span className="sr-only">Loading...</span>
            </div>
        ))}
    </div>
);

export default Placeholder;
